import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import 'rxjs';
import {forkJoin} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {switchMap} from 'rxjs/operators';
import {API_ADD, API_DELETE, API_EDIT} from './utils/constants';
import {environment} from '../../environments/environment';

@Injectable()
export class ContentApiService {

    url = environment.apiUrl;

    constructor(private http: HttpClient, private auth: AuthenticationService) {
    }


    getVideosByCategory(category: string) {
        return this.http
            .get(this.url + 'api/categories/' + category + '/videos');
    }

    getVideoSingle(id: string) {
        return this.http
            .get(this.url + 'api/videos/' + id);
    }

    updateVideo(data, id) {
        return forkJoin([this.updateVideoName(data.name, id), this.updateVideoDescription(data.description, id)]);
    }

    updateVideoName(name, id) {
        return this.http.patch(this.url + 'api/videos/' + id + '/name', {name});

    }

    updateVideoDescription(description, id) {
        return this.http.patch(this.url + 'api/videos/' + id + '/description', {description});
    }

    updateVideoCategory(category, id) {
        return this.http.patch(this.url + 'api/videos/' + id + '/category', {category});
    }

    deleteVideoById(id) {
        return this.http.delete(this.url + 'api/videos/' + id);
    }

    getStandards() {
        return this.http
            .get(this.url + 'api/functions/standards');
    }

    updateStandard(data, id) {
        return forkJoin([this.updateStandardName(data.name, id), this.updateStandardDescription(data.description, id)]);
    }

    updateStandardName(name, id) {
        return this.http.patch(this.url + 'api/standards/' + id + '/name', {name});

    }

    updateStandardDescription(description, id) {
        return this.http.patch(this.url + 'api/standards/' + id + '/description', {description});
    }

    getFieldGuides() {
        return this.http
            .get(this.url + 'api/guides');
    }

    updateFieldGuide(data, id) {
        return forkJoin([this.updateFieldGuideName(data.name, id), this.updateFieldGuideSummary(data.summary, id)]);
    }

    updateFieldGuideName(name, id) {
        return this.http.patch(this.url + 'api/guides/' + id + '/name', {name});

    }

    updateFieldGuideSummary(summary, id) {
        return this.http.patch(this.url + 'api/guides/' + id + '/summary', {summary});
    }

    getContainers() {
        return this.http
            .get(this.url + 'api/functions/standards');
    }

    addNewContainer(name, parentId) {
        return this.http.post(this.url + 'api/functions', {
            name,
            parent: parentId
        });
    }

    deleteContainer(containerId) {
        return this.http.delete(this.url + 'api/functions/' + containerId, {});
    }

    deleteStandardFile(standardId) {
        return this.http.delete(this.url + 'api/standards/' + standardId, {});
    }

    deleteVideoFile(videoId) {
        return this.http.delete(this.url + 'api/videos/' + videoId, {});
    }

    getStandardSingle(id: string) {
        return this.http
            .get(this.url + 'api/standards/' + id);
    }

    getStandardSingleFile(id: string) {
        return this.http
            .get(this.url + 'api/standards/' + id + '/file');
    }

    deleteStandardById(id) {
        return this.http.delete(this.url + 'api/standards/' + id);
    }

    deleteFieldGuideById(id) {
        return this.http.delete(this.url + 'api/guides/' + id);
    }

    getQuizzesByCategory(category: string) {
        return this.http
            .get(this.url + 'api/categories/' + category + '/quizzes', {
                headers: {BEARER: this.auth.getAccessToken()}
            });
    }

    getQuizzesBasicByCategory(category: string) {
        return this.http
            .get(this.url + 'api/categories/' + category + '/quizzes/simple');
    }

    getQuizById(quizId) {
        return this.http
            .get(this.url + 'api/quizzes/' + quizId);
    }

    deleteQuizById(quizId) {
        return this.http
            .delete(this.url + 'api/quizzes/' + quizId);
    }


    addQuiz(quiz, categoryName) {
        return this.createQuiz(quiz)
            .pipe(switchMap(response => {
                const questions = [];
                if (quiz.questions) {
                    quiz.questions.forEach((question) => {
                        delete question.id;
                        delete question.action;
                        if (question.answers) {
                            question.answers.forEach((answer) => {
                                delete answer.action;
                                delete answer.id;
                            });
                        }
                        // @ts-ignore
                        const {id} = response;
                        questions.push(this.addQuestionAnswersSet(id, question));
                    });
                }
                return forkJoin(questions);
            }));

    }

    updateQuiz(quiz) {
        const questions = [];
        const answers = [];
        quiz.questions.forEach((question) => {

            question.answers.forEach((answer) => {
                switch (answer.action) {
                    case API_DELETE:
                        if (question.action !== API_DELETE) {
                            answers.push(this.deleteAnswer(answer));
                        }
                        break;
                    case API_EDIT:
                        answers.push(this.updateAnswer(answer));
                        break;

                    case API_ADD:
                        if (question.action !== API_ADD) {
                            answers.push(this.addAnswer(answer, question.id));
                        }
                        break;

                }
                delete answer.id;

            });
            switch (question.action) {
                case API_DELETE:
                    questions.push(this.deleteQuestion(question.id));
                    break;

                case API_EDIT:
                    questions.push(this.updateQuestion(question));
                    break;

                case API_ADD:
                    questions.push(this.addQuestionAnswersSet(quiz.id, question));
                    break;

            }
            delete question.id;

        });
        const sources = [this.updateQuizCore(quiz)];
        if (answers.length > 0) {
            sources.push(forkJoin(answers));
        }
        if (questions.length > 0) {
            sources.push(forkJoin(questions));
        }
        return forkJoin(sources);
    }

    updateAnswer(answer) {
        delete answer.action;
        return forkJoin([this.http.patch(this.url + 'api/answers/' + answer.id + '/text', {text: answer.answer}),
            this.http.patch(this.url + 'api/answers/' + answer.id + '/correct', {isCorrect: answer.isCorrect})]);
    }

    updateQuestion(question) {
        delete question.action;
        return this.http.patch(this.url + 'api/questions/' + question.id + '/text', {text: question.question});
    }

    deleteAnswer(answer) {
        delete answer.action;
        return this.http.delete(this.url + 'api/answers/' + answer.id);
    }

    deleteQuestion(question) {
        delete question.action;
        return this.http.delete(this.url + 'api/questions/' + question);
    }

    updateQuizCore(quiz) {
        if (quiz.category) {
            return forkJoin([this.http.patch(this.url + 'api/quizzes/' + quiz.id + '/title', {title: quiz.title}),
                this.http.patch(this.url + 'api/quizzes/' + quiz.id + '/category', {category: quiz.category})
            ]);
        } else {
            return this.http.patch(this.url + 'api/quizzes/' + quiz.id + '/title', {title: quiz.title});
        }
    }


    addQuestionAnswersSet(quizId, question) {
        delete question.action;
        delete question.id;
        question.answers.forEach((answer) => {
            delete answer.action;
            delete answer.id;
        });
        return this.http.post(this.url + 'api/quizzes/' + quizId + '/questions/answers', question);

    }

    addAnswer(answer, questionID) {
        delete answer.id;
        delete answer.action;
        return this.http.post(this.url + 'api/questions/' + questionID + '/answers', answer);

    }

    createQuiz(quiz) {
        return this.http.post(this.url + 'api/quizzes', {
            title: quiz.title,
            category: quiz.category
        });
    }

    //
    updateContainerName(containerId, name) {
        // return this.http.patch(this.url + 'api/containers/' + containerId, {
        //     name: name,
        // });
        return this.http.patch(this.url + 'api/containers/' + containerId + '/name', {name});

    }

    //
    getQrOnEmailForStandard(standardId) {
        return this.http
            .get(this.url + 'api/standards/' + standardId + '/qr');
    }

    //
    getQrOnEmailForVideo(videoId) {
        return this.http
            .get(this.url + 'api/videos/' + videoId + '/qr');
    }

    //
    getAudits() {
        return this.http
            .get(this.url + 'api/audits');
    }

    addNewAudit(name) {
        return this.http.post(this.url + 'api/audits', {
            name
        });
    }

    //
    updateAuditName(auditId, name) {
        return this.http.patch(this.url + 'api/audits/' + auditId + '/name', {
            name,
        });
    }

    //
    deleteAudit(auditId) {
        return this.http.delete(this.url + 'api/audits/' + auditId, {});
    }

    //
    getAuditFunctions() {
        return this.http
            .get(this.url + 'api/functions');
    }

    //
    addNewAuditFunction(name, parentId?) {
        return this.http.post(this.url + 'api/functions', {
            name,
            parent: parentId ? parentId : 0
        });
    }

    //
    addMultipleAuditFunctions(functions) {
        return this.http.post(this.url + 'api/functions/import', {
            functions
        });
    }


    updateAuditFunctionName(functionId, name) {
        return this.http.patch(this.url + 'api/functions/' + functionId + '/name', {
            name,
        });
    }

    //
    deleteAuditFunction(functionId) {
        return this.http.delete(this.url + 'api/functions/' + functionId, {});
    }

    //
    getAuditLocations() {
        return this.http
            .get(this.url + 'api/locations');
    }

    //
    addNewAuditLocation(name, parentId) {
        return this.http.post(this.url + 'api/locations', {
            name,
            parent: parentId
        });
    }

    //
    addMultipleAuditLocations(locations) {
        return this.http.post(this.url + 'api/locations/import', {
            locations
        });
    }

    //
    updateAuditLocationName(locationId, name) {
        return this.http.patch(this.url + 'api/locations/' + locationId + '/name', {
            name,
        });
    }

    //
    deleteAuditLocation(locationId) {
        return this.http.delete(this.url + 'api/locations/' + locationId, {});
    }

    //
    addNewSubjectToAudit(auditId, subjectName) {
        return this.http.post(this.url + 'api/audits/' + auditId + '/subjects', {
            subject: subjectName
        });
    }

    //
    updateAuditSubjectName(subjectId, name) {
        return this.http.patch(this.url + 'api/subjects/' + subjectId + '/text', {
            subject: name,
        });
    }

    //
    deleteAuditSubject(subjectId) {
        return this.http.delete(this.url + 'api/subjects/' + subjectId, {});
    }

    //
    addNewStatementToSubject(subjectId, post_data) {
        return this.http.post(this.url + 'api/subjects/' + subjectId + '/statements', post_data);
    }

    //
    updateStatement(statementId, post_data) {
        // let headers = new HttpHeaders();
        // headers = headers.set('content-type', 'text/plain');
        return this.http.put(this.url + 'api/statements/' + statementId, post_data);
    }

    //
    updateAuditStatementName(statementId, name) {
        return this.http.patch(this.url + 'api/statements/' + statementId + '/text', {
            statement: name,
        });
    }

    //
    deleteAuditStatement(statementId) {
        return this.http.delete(this.url + 'api/statements/' + statementId, {});
    }

    // ----analytics
    getAnalyticsFunctions() {
        return this.http
            .get(this.url + 'api/analytics/functions');
    }

    getAnalyticsLocations() {
        return this.http
            .get(this.url + 'api/analytics/locations');
    }

    getAnalyticsFunctionsAndLocations() {
        return forkJoin([this.getAnalyticsFunctions(), this.getAnalyticsLocations()]);
    }

    getAnalyticsAudits() {
        return this.http
            .get(this.url + 'api/analytics/audits');
    }


    getAnalytics(auditString, functionPasssed, location, startDate, endDate, type) {
        let params = new HttpParams();
        if (auditString) {
            params = params.append('auditString', auditString);
        }
        if (functionPasssed) {
            params = params.append('function', String(functionPasssed));
        }
        if (location) {
            params = params.append('location', String(location));
        }
        if (startDate) {
            params = params.append('startDate', String(startDate));
        }
        if (endDate) {
            params = params.append('endDate', String(endDate));
        }
        if (type) {
            params = params.append('type', type);
        }
        return this.http
            .get(this.url + 'api/analytics', {params});
    }

    getAnalyticsURL(auditString, functionPasssed, location, startDate, endDate, type) {
        let params = '?';

        if (auditString) {
            params += 'auditString=' + String(auditString);
        }
        if (functionPasssed) {
            params += 'function=' + String(functionPasssed);
        }
        if (location) {
            params += '&location=' + String(location);
        }
        if (startDate) {
            params += '&startDate=' + String(startDate);
        }
        if (endDate) {
            params += '&endDate=' + String(endDate);
        }
        if (type) {
            params += '&type=' + type;
        }
        return this.url + 'api/analytics' + params;
    }

    updateSubjectPosition(subjectId, direction) {
        return this.http.patch(this.url + 'api/subjects/' + subjectId + '/position', {
            direction: direction,
        });
    }

    updateStatementPosition(statementId, direction) {
        return this.http.patch(this.url + 'api/statements/' + statementId + '/position', {
            direction: direction,
        });
    }

    getLinearFunctions() {
        return this.http
            .get(environment.apiUrl + 'api/functions/linear');
    }

    //
    getLinearLocations() {
        return this.http
            .get(environment.apiUrl + 'api/locations/linear');
    }

    updateLocationParent(locationId, parentId) {
        return this.http.patch(environment.apiUrl + `api/locations/${locationId}/parent`, {parent: parentId});
    }

    updateFunctionParent(functionId, parentId) {
        return this.http.patch(environment.apiUrl + `api/functions/${functionId}/parent`, {parent: parentId});
    }

    updateFunction(functionId, name = null, parentNodeId = null) {
        let calls = []
        if (name) {
            calls.push(this.updateAuditFunctionName(functionId, name))
        }
        if (parentNodeId) {
            calls.push(this.updateFunctionParent(functionId, parentNodeId))
        }
        return forkJoin(calls)
    }

    updateLocation(locationId, name = null, parentNodeId = null) {
        let calls = []
        if (name) {
            calls.push(this.updateAuditLocationName(locationId, name))
        }
        if (parentNodeId) {
            calls.push(this.updateLocationParent(locationId, parentNodeId))
        }
        return forkJoin(calls)
    }
}
