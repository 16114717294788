import {Injectable} from '@angular/core';
import {GlobalApiService} from '../global.api.service';
import {environment} from '../../../environments/environment';
import {TYPE_RM} from './constants';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class CustomTermsProvider {
    isDemo = environment.typeEnv !== TYPE_RM;
    initialised = new BehaviorSubject(false);
    terms = [
        {
            id: 'string',
            default: 'c slip',
            term: 'cSlip',
            pluralTerm: 'cSlips',
            slug: '3c'
        },
        {
            id: 'string',
            default: 'ci',
            term: 'CI',
            pluralTerm: 'CI',
            slug: 'ci'
        }
    ];

    constructor(private globalApi: GlobalApiService) {
    }

    init() {
        if (!this.isDemo) {
            this.terms = [
                {
                    id: 'string',
                    default: 'c slip',
                    term: 'concern',
                    pluralTerm: 'concerns',
                    slug: '3c'
                },
                {
                    id: 'string',
                    default: 'ci',
                    term: 'improvement',
                    pluralTerm: 'improvements',
                    slug: 'ci'
                },
                {
                    id: 'string',
                    default: 'improvement',
                    term: 'improvement',
                    pluralTerm: 'improvements',
                    slug: 'continuous-improvement'
                },
                {
                    id: 'string',
                    default: 'function',
                    term: 'work area',
                    pluralTerm: 'work areas',
                    slug: 'function'
                },
                {
                    id: 'string',
                    default: 'location',
                    term: 'location',
                    pluralTerm: 'locations',
                    slug: 'location'
                },
                {
                    id: 'string',
                    default: 'Workplace Coaching',
                    term: 'Workplace Coaching',
                    pluralTerm: 'Workplace Coachings',
                    slug: 'workplace-coaching'
                }
            ];
        } else {
            this.terms = [
                {
                    id: 'string',
                    default: 'c slip',
                    term: '3C',
                    pluralTerm: '3C',
                    slug: '3c'
                },
                {
                    id: 'string',
                    default: 'ci',
                    term: 'CI',
                    pluralTerm: 'CI',
                    slug: 'ci'
                },
                {
                    id: 'string',
                    default: 'continuous improvement',
                    term: 'continuous improvement',
                    pluralTerm: 'continuous improvements',
                    slug: 'continuous-improvement'
                },
                {
                    id: 'string',
                    default: 'function',
                    term: 'function',
                    pluralTerm: 'functions',
                    slug: 'function'
                },
                {
                    id: 'string',
                    default: 'location',
                    term: 'location',
                    pluralTerm: 'locations',
                    slug: 'location'
                },
                {
                    id: 'string',
                    default: 'Workplace Coaching',
                    term: 'Workplace Coaching',
                    pluralTerm: 'Workplace Coachings',
                    slug: 'workplace-coaching'
                }
            ]
        }
        this.globalApi.getCustomTerms().subscribe(res => {
            if (res) {
                // @ts-ignore
                this.terms = res;
                this.initialised.next(true);

            }

        });

    }

    capitalize(str) {
        if (typeof str === 'string') {
            return str.replace(/^\w/, c => c.toUpperCase());
        } else {
            return '';
        }
    };

    getSlugs() {
        return this.terms.map(it => it.slug);
    }

    getTermBySlug(slug) {
        let custom = this.terms.find(it => it.slug === slug);
        return custom?.term  ? this.capitalize(custom?.term) : this.capitalize(custom?.default);
    }

    getCIterm() {
        let custom = this.terms.find(it => it.slug === 'ci');
        return custom?.term  ? this.capitalize(custom?.term) : this.capitalize(custom?.default);
    }

    getCIpluralTerm() {
        let custom = this.terms.find(it => it.slug === 'ci');
        return custom?.pluralTerm  ? this.capitalize(custom?.pluralTerm) : this.capitalize(custom?.default);
    }
    getWCterm() {
        let custom = this.terms.find(it => it.slug === 'workplace-coaching');
        return custom?.term  ? this.capitalize(custom?.term) : this.capitalize(custom?.default);
    }

    getWCpluralTerm() {
        let custom = this.terms.find(it => it.slug === 'workplace-coaching');
        return custom?.pluralTerm  ? this.capitalize(custom?.pluralTerm) : this.capitalize(custom?.default);
    }
    getContinuousImprovementTerm() {
        let custom = this.terms.find(it => it.slug === 'continuous-improvement');
        return custom?.term  ? this.capitalize(custom?.term) : this.capitalize(custom?.default);
    }

    getContinuousImprovementPluralTerm() {
        let custom = this.terms.find(it => it.slug === 'continuous-improvement');
        return custom?.pluralTerm  ? this.capitalize(custom?.pluralTerm) : this.capitalize(custom?.default);
    }

    getCSlipterm() {
        let custom = this.terms.find(it => it.slug === '3c');
        return custom?.term  ? this.capitalize(custom?.term) : this.capitalize(custom?.default);
    }

    getCSlipPluralTerm() {
        let custom = this.terms.find(it => it.slug === '3c');
        return custom?.pluralTerm  ? this.capitalize(custom?.pluralTerm) : this.capitalize(custom?.default);
    }

    getFunctionTerm() {
        let custom = this.terms.find(it => it.slug === 'function');
        return custom?.term  ? this.capitalize(custom?.term) : this.capitalize(custom?.default);
    }

    getFunctionPluralTerm() {
        let custom = this.terms.find(it => it.slug === 'function');
        return custom?.pluralTerm !== '' ? this.capitalize(custom?.pluralTerm) : this.capitalize(custom?.default);
    }

    getLocationTerm() {
        let custom = this.terms.find(it => it.slug == 'location');
        return custom?.term  ? this.capitalize(custom?.term) : this.capitalize(custom?.default);
    }

    getLocationPluralTerm() {
        let custom = this.terms.find(it => it.slug == 'location');
        return custom?.pluralTerm  ? this.capitalize(custom?.pluralTerm) : this.capitalize(custom?.default);
    }
}
